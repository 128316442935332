import React from 'react';
import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { Modal } from 'antd';
import { config } from './config';

const uri = (protocol: string) => `${protocol}://${config.GRAPHQL_DOMAIN}/graphql`;

export default function apolloClient(language: string, token?: string | null) {
  const httpLink = createHttpLink({
    uri: uri(`http${config.SSL_ENABLED ? 's' : ''}`),
  });

  const wsLink = new WebSocketLink({
    uri: uri(`ws${config.SSL_ENABLED ? 's' : ''}`),
    options: {
      reconnect: true,
    },
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...((token && { Authorization: `Bearer ${token}` }) || {}),
        'x-language': language,
      },
      onError: ({ graphQLErrors, networkError }: any) => {
        if (graphQLErrors)
          graphQLErrors.forEach(
            ({ message, locations, path }: any) => {
              Modal.error({
                style: { width: '500px', height: '500px' },
                width: 700,
                title: 'Network error — provide this report to system administrator',
                content: (
                  <>
                    {/* <li>{locations}</li> */}
                    <div>
                      <strong>Type</strong>: {networkError && networkError.toString()}
                    </div>
                    <div>
                      <strong>URL</strong>: <a href={window.location.href}>{window.location.href}</a>
                    </div>
                    <div>
                      <strong>Path</strong>: {path || '—'}
                    </div>
                    <div>
                      <strong>Location</strong>: {JSON.stringify(locations)}
                    </div>
                    <div>
                      <strong>Message</strong>:<br /> {message}
                    </div>
                  </>
                ),
                onOk() {},
              });
            },
            // console.log(
            //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            // ),
          );

        if (networkError) console.log(`[Network error]: ${networkError}`);
        return;
      },
    };
  });

  return new ApolloClient({
    link: split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      authLink.concat(httpLink),
    ),
    cache: new InMemoryCache(),
  });
}
