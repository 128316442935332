import * as React from 'react';
import { UserT } from 'types/User';

type ContextT = {
  language: string,
  dir: string,
  loggedIn: boolean,
  getRole: any,
  getAccessToken: any,
  setAccessToken: (token: string) => void,
  changeLanguage: (language: string) => void,
  getUser: any,
  user?: UserT,
}

export const ACCESS_TOKEN_KEY = 'hotelFarmaDashboardAccessToken';
export const AppContext = React.createContext<ContextT>({
  language: 'en',
  dir: 'ltr',
  loggedIn: false,
  getRole: (): String | null => { return 'anonymous' },
  getAccessToken: (): String | null | undefined => { return localStorage.getItem(ACCESS_TOKEN_KEY) },
  setAccessToken: (token: string) => { localStorage.setItem(ACCESS_TOKEN_KEY, token) },
  changeLanguage: (language: string) => { },
  getUser: (): any => { return {} },
  user: undefined,
});
