import gql from 'graphql-tag';

export const LOGIN = gql`mutation login($email: String $password: String) {
  login(data: { email: $email password: $password}) {
    accessToken
  }
}`;


export const GET_USER_BY_TOKEN = gql`query users($accessToken: String) {
  users(where: { accessToken: $accessToken }) {
    id
    name
    email
  }
}`;
