import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const NotificationPage = React.lazy(() =>
  import(/* webpackChunkName: "NotificationsPage" */ './Pages/NotificationsPage'),
);

const Router = () => {
  return (
    <Switch>
      <Route
        path='/notifications/:entityId?'
        component={({
          history,
          match: {
            params: { entityId },
          },
          location: { search, pathname },
        }) => <NotificationPage entityId={entityId} history={history} pathname={pathname} search={search} />}
      />
      <Route path='*'>
        <Redirect to='/menus/meals' />
      </Route>
    </Switch>
  );
};

export default Router;
