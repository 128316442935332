import React from 'react';
import { Spin, Icon } from 'antd';
import css from './globalLoader.scss';

const GlobalLoader = () => {
  return (
    <Spin className={css.globalLoader} indicator={<Icon type="loading" spin />} />
  )
}

export default GlobalLoader;
