import React, { useContext, useState } from 'react';
import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import css from './App.scss';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ComponentLoader from './Pages/ComponentLoader';
import { useQuery, ApolloProvider } from '@apollo/react-hooks';
import { AppContext, ACCESS_TOKEN_KEY } from './utils/AppContext';
import apolloClient from './utils/apolloClient';
import { GET_USER_BY_TOKEN } from './Pages/Login/queries';
import Router from './router';
import * as jwt from 'jsonwebtoken';
import GlobalLoader from 'partials/GlobalLoader';
import { config } from 'utils/config';

const ALL_ROLES = ['accountant', 'operator', 'sales', 'data-entry', 'supervisor', 'admin'];

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

function AppMenu(props: any) {
  const { sectionKey, linkKey, navigation, createTab } = props;
  return (
    <AppContext.Consumer>
      {({ language, changeLanguage, setAccessToken, getRole, getUser }: any) => (
        <Menu
          selectedKeys={[`${sectionKey}:${linkKey}`]}
          mode='horizontal'
          theme='dark'
          style={config.ENV === 'master' ? undefined : { background: '#1890ff' }}
        >
          {Object.keys(navigation)
            .filter(
              (nodeKey: string) =>
                !navigation[nodeKey].roles || navigation[nodeKey].roles.includes(getRole()),
            )
            .map((nodeKey: any) => {
              const node = navigation[nodeKey];

              const urlKey = node.filterQueryKey || nodeKey;

              return (
                <SubMenu
                  key={nodeKey}
                  title={
                    <span className={css['submenu-title-wrapper']}>
                      <Icon type='appstore' />
                      {node.name}
                    </span>
                  }
                >
                  {node.menu &&
                    Object.keys(node.menu)
                      .filter(
                        (childKey: string) =>
                          !Array.isArray(node.menu[childKey].roles) ||
                          node.menu[childKey].roles.includes(getRole()),
                      )
                      .map((childKey: string) => {
                        const child = node.menu[childKey];
                        const conditions = child['conditions'];

                        return (
                          <Menu.Item key={`${nodeKey}:${childKey}`}>
                            <Link
                              to={`/${nodeKey}/${childKey}${(conditions &&
                                `?${urlKey}_conditions=${JSON.stringify(conditions)}`) ||
                                ''}`}
                              onClick={() =>
                                createTab(`/${nodeKey}/${childKey}`, `${node.name} — ${child.name}`)
                              }
                            >
                              {child.name}
                            </Link>
                          </Menu.Item>
                        );
                      })}
                </SubMenu>
              );
            })}

          <SubMenu
            title={
              <span className={css['submenu-title-wrapper']}>
                <Icon type='user' />
                {getUser().name}
              </span>
            }
            style={{ float: 'right' }}
          >
            <Menu.Item onClick={() => changeLanguage('en')}>English</Menu.Item>
            <Menu.Item onClick={() => changeLanguage('cs')}>Česky</Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={() => setAccessToken(null)}>
              <Icon type='logout' />
              Logout
            </Menu.Item>
          </SubMenu>
          {/* <SubMenu title={<NotificationBell />} style={{ float: 'right', width: 45 }} /> */}
        </Menu>
      )}
    </AppContext.Consumer>
  );
}

function buildNavigations(workflows: any[], userId: string): any {
  const navigation = {
    allergens: {
      name: 'Alergeny',
      roles: ALL_ROLES,
      menu: {
        allergens: {
          name: 'Alergeny',
          roles: ALL_ROLES,
          component: React.lazy(() => import(/* webpackChunkName: "allergens" */ './Pages/Lists/Allergens')),
          form: React.lazy(() => import(/* webpackChunkName: "allergens-form" */ './Pages/Forms/Allergens')),
        },
      },
    },
    mealsdrinks: {
      name: 'Jídla a nápoje',
      roles: ALL_ROLES,
      menu: {
        meals: {
          name: 'Jídla',
          roles: ALL_ROLES,
          component: React.lazy(() => import(/* webpackChunkName: "meals" */ './Pages/Lists/Meals')),
          form: React.lazy(() => import(/* webpackChunkName: "meals-form" */ './Pages/Forms/Meals')),
        },
        drinks: {
          name: 'Nápoje',
          roles: ALL_ROLES,
          component: React.lazy(() => import(/* webpackChunkName: "drinks" */ './Pages/Lists/Drinks')),
          form: React.lazy(() => import(/* webpackChunkName: "drinks-form" */ './Pages/Forms/Drinks')),
        },
        mealCategories: {
          name: 'Kategorie jídel',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "meal-categories" */ './Pages/Lists/MealCategories'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "meal-categories-form" */ './Pages/Forms/MealCategories'),
          ),
        },
        drinkCategories: {
          name: 'Kategorie nápojů',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "drink-categories" */ './Pages/Lists/DrinkCategories'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "drink-categories-form" */ './Pages/Forms/DrinkCategories'),
          ),
        },
      },
    },
    menus: {
      name: 'Menu',
      roles: ALL_ROLES,
      menu: {
        meals: {
          name: 'Jídelní lístky',
          roles: ALL_ROLES,
          component: React.lazy(() => import(/* webpackChunkName: "meal-menus" */ './Pages/Lists/MealMenus')),
          form: React.lazy(() => import(/* webpackChunkName: "meal-menus-form" */ './Pages/Forms/MealMenus')),
        },
        drinks: {
          name: 'Nápojové lístky',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "drink-menus" */ './Pages/Lists/DrinkMenus'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "drink-menus-form" */ './Pages/Forms/DrinkMenus'),
          ),
        },
      },
    },
    permissions: {
      name: 'Oprávnění',
      roles: ['admin'],
      menu: {
        users: {
          name: 'Uživatelé',
          roles: ['admin'],
          component: React.lazy(() => import(/* webpackChunkName: "users" */ './Pages/Lists/Users')),
          form: React.lazy(() => import(/* webpackChunkName: "users-form" */ './Pages/Forms/Users')),
        },
        roles: {
          name: 'Role',
          roles: ['admin'],
          component: React.lazy(() => import(/* webpackChunkName: "roles" */ './Pages/Lists/Roles')),
          form: React.lazy(() => import(/* webpackChunkName: "roles-form" */ './Pages/Forms/Roles')),
        },
      },
    },
  };

  return navigation;
}

function Application() {
  const [language, setLanguage] = useState('cs');
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <AppContext.Provider
      value={{
        user: jwt.decode(localStorage.getItem(ACCESS_TOKEN_KEY) || '') as any, // todo better
        language,
        dir: 'ltr',
        loggedIn,
        getRole: (): string | null => {
          const user = jwt.decode(localStorage.getItem(ACCESS_TOKEN_KEY) || '') as any; // todo better
          return user ? user.role : null;
        },
        getUser: (): any => {
          return jwt.decode(localStorage.getItem(ACCESS_TOKEN_KEY) || ''); // todo better
        },
        getAccessToken: (): string | null | undefined => {
          return localStorage.getItem(ACCESS_TOKEN_KEY);
        },
        setAccessToken: (accessToken: string) => {
          localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
          if (!accessToken) {
            localStorage.removeItem(ACCESS_TOKEN_KEY);
          }

          setLoggedIn(accessToken ? true : false);
        },
        changeLanguage: language => {
          setLanguage(language);
        },
      }}
    >
      <AppApolloProvider />
    </AppContext.Provider>
  );
}

function AppApolloProvider() {
  const { language, getAccessToken } = useContext(AppContext);
  return (
    <ApolloProvider client={apolloClient(language, getAccessToken())}>
      <ApplicationContentWithRouter />
    </ApolloProvider>
  );
}

function ApplicationContent(props: any) {
  const { user, dir, getAccessToken, setAccessToken, loggedIn, getRole } = useContext(AppContext);

  const accessToken = getAccessToken();
  const { loading: userLoading, data: userData } = useQuery(GET_USER_BY_TOKEN, {
    variables: { accessToken },
  });

  if (userLoading) {
    return <GlobalLoader />;
  }

  // if exists user, update login
  if (userData.users && userData.users[0] && accessToken) {
    setAccessToken(accessToken);
  }

  if (!loggedIn || !user) {
    const Login = React.lazy(() => import(/* webpackChunkName: "login" */ './Pages/Login/index'));

    return (
      <React.Suspense fallback={<ComponentLoader />}>
        <Login setAccessToken={setAccessToken} />
      </React.Suspense>
    );
  }

  const navigation = buildNavigations([], user.id);

  const {
    location: { pathname, search },
  } = props;
  const urlQuery = new URLSearchParams(search);
  const partials = pathname.substr(1).split('/');
  // default section are business cases
  const sectionKey = partials[0] || 'bc';
  const linkKey = partials[1] || '';
  const entityId = partials[2];

  const section = navigation[sectionKey];
  const page = section && section.menu && section.menu[linkKey];
  const conditions = urlQuery.get(`${sectionKey}_conditions`)
    ? JSON.parse(urlQuery.get(`${sectionKey}_conditions`) as any)
    : {};
  const isSidebar = false; /// temporarily disabled for all agendas (page && page.sidebar !== undefined ? page.sidebar : section && section.sidebar) || false;

  const ComponentPageNotFound = React.lazy(() =>
    import(/* webpackChunkName: "ComponentNotFound" */ './Pages/ComponentNotFound'),
  );
  const ComponentToRender = (page && (entityId ? page.form : page.component)) || ComponentPageNotFound;

  const hasRouterPath = !!(page || {}).hasRouterPath;
  const hasAccess = page && ((page.roles && page.roles.includes(getRole())) || !page.roles);
  return (
    <Layout dir={dir}>
      <AppMenu
        sectionKey={sectionKey}
        linkKey={linkKey}
        section={section}
        navigation={navigation}
        createTab={() => {} /*this.createTab*/}
      />
      <Layout>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            {navigation[sectionKey] && <Breadcrumb.Item>{navigation[sectionKey].name}</Breadcrumb.Item>}
            {page && <Breadcrumb.Item>{page.name}</Breadcrumb.Item>}
          </Breadcrumb>
          <Content
            style={{
              background: '#fff',
              margin: 0,
              minHeight: 280,
            }}
          >
            <React.Suspense fallback={<ComponentLoader />}>
              {hasAccess && !hasRouterPath && (
                <ComponentToRender
                  createTab={() => {} /*this.createTab*/}
                  entityId={entityId}
                  conditions={conditions}
                  data={entityId === 'new' ? {} : undefined}
                  createFormConditions={page.createFormConditions}
                />
              )}
              {(!hasAccess || hasRouterPath) && <Router />}
            </React.Suspense>
          </Content>
        </Layout>
        {isSidebar && (
          <Sider width={200}>
            <Menu
              selectedKeys={[`${sectionKey}:${linkKey}`]}
              mode='inline'
              style={{ height: '100%', borderRight: 0 }}
            >
              {Object.keys(section.menu).map((nodeKey: any) => {
                const node = section.menu[nodeKey];
                const nodeConditions = section.menu['conditions'];
                return (
                  <Menu.Item key={`${sectionKey}:${nodeKey}`}>
                    <Link
                      to={`/${sectionKey}${(nodeConditions &&
                        `?${sectionKey}_conditions=${nodeConditions}`) ||
                        ''}`}
                    >
                      {node.name}
                    </Link>
                  </Menu.Item>
                );
              })}
            </Menu>
          </Sider>
        )}
      </Layout>
    </Layout>
  );
}

const ApplicationContentWithRouter = withRouter(ApplicationContent);

export default Application;
